import { TENANT_GROUPS } from '@/config'

const state = {
  sidebarVisible: '',
  sidebarUnfoldable: false,
  pointSettings: [],
  roles: [],
  role: {},
  user: {},
  user_admin: {},
  admin_role: [],
  users: [],
  permissions: [],
  permissionsModel: [],
  categoriesModel: [],
  optionsModel: [],
  permissionsSelect: [],
  categoriesSelect: [],
  filter: {},
  categories: [],
  apps: [],
  products: [],
  trainings: [],
  category: {},
  langs: [],
  category_update: [],
  product_update: [],
  product: {},
  vendors: [],
  file: {},
  vendorsSelect: [],
  attributes: [],
  attribute: {},
  options: [],
  optionsSelect: [],
  attributesSelect: [],
  attributesModel: [],
  option: {},
  imageFile: {},
  isLoading: false,
  files: [],
  userFiles: [],
  import: false,
  userModel: {},
  productsSelect: [],
  vendorList: [],
  vendor: {},
  suppliersList: [],
  supplier: {},
  suppliersSelect: [],
  suppliers: [],
  rolesSelect: [],
  addressStreet: {},
  addressCity: {},
  training: {},
  questions: [],
  bTrainings: [],
  bTraining: {},
  bTrainingTypes: {},
  bTrainingCosts: {},
  bTrainingsDetails: [],
  pagination: [],
  paginationActivity: [],
  paginationGlobalActivity: [],
  groupsModel: [],
  groups: [],
  group: null,
  slides: [],
  slide: {},
  orders: [],
  messages: [],
  message: {},
  threads: [],
  order: null,
  textPages: [],
  textPage: {},
  setting: null,
  featured_products: [],
  toast: [],
  isPending: false,
  threadsBySlug: [],
  threadsUsers: [],
  stats: [],
  trainingResults: [],
  bTrainingResults: [],
  externalTrainingResults: [],
  hunterResults: [],
  newsFeed: [],
  newsFeeds: [],
  warehouses: [],
  warehouseCount: 0,
  salesPromotions: [],
  menuVisible: true,
  importedProducts: [],
  importedProductsTotal: 0,
  hunterDetails: [],
  hunterDetail: [],
  dealers: [],
  customer: {},
  points: false,
  boosterFields: [],
  wholesalers: [],
  userOrders: [],
  bankStatement: [],
  loginHistory: [],
  loginsHistory: [],
  detetedUsers: [],
  prices: [],
  price: {},
  priceCategories: [],
  priceList: [],
  singlePriceList: {},
  staffs: [],
  requestStats: [],
  inactiveUsers: [],
  complaints: [],
  complaint: {},
  certificateTexts: [],
  activities: [],
  activitiesGlobal: [],
  activityFilters: {},
  csNumberToApprove: [],
  messageIbanStatus: [],
  statusMatch: '',
  boostersList: [],
  inactiveBoosters: [],
  boostersPreview: [],
  userBoosterSelect: [],
  trainingFilters: [],
  promoFilters: [],
  orderStats: [],
  checks: [],
  userFilters: [],
  menuList: [],
  mediaCategories: [],
  medias: [],
  mediaPage: {},
  mediaPageFiles: [],
  uberallResults: [],
  parentGroups: [],
  wholesalerManagements: [],
  connections: [],
  defaultBanners: [],
  inActiveCustomerNumbers: [],
  initialUserRole: [],
  shopLimits: [],
  totalMediaFiles: 0,
  mediaFiles: [],
  voucherSelect: [],
  vouchers: [],
  brochureCategories: [],
  brochures: [],
  brochure: {},
  brochureFiles: [],
  surveys: [],
  surveysActivated: [],
  survey: [],
  survey_groups: [],
  filterUsers: {
    page: 1
  },
  mainBoxes: [],
  mainBox: {},
  liteVersionCountries: TENANT_GROUPS.liteVersion,
  userApps: [],
  parentAttributes: [],
  parentAttributesPending: false,
  initialParentAttributes: [],
  availableModules: [],
  tenantId: null,
  tenancyEnabled: false,
  usersPending: false,
  bothRelationOptionDisabled: ['PT', 'ES'],
  countryToLocaleMap: {
    DK: { default: 'da-DK', available: ['da-DK'] },
    FI: { default: 'fi-FI', available: ['fi-FI'] },
    SE: { default: 'sv-SE', available: ['sv-SE'] },
    AT: { default: 'de-AT', available: ['de-AT'] },
    PT: { default: 'pt-PT', available: ['pt-PT'] },
    ES: { default: 'es-ES', available: ['es-ES'] },
    FR: { default: 'fr-FR', available: ['fr-FR'] },
    BE: { default: 'fr-BE', available: ['fr-BE', 'nl-BE'] },
    DE: { default: 'de-DE', available: ['de-DE'] },
    NL: { default: 'nl-NL', available: ['nl-NL'] },
    IT: { default: 'it-IT', available: ['it-IT'] },
    CH: { default: 'de-CH', available: ['de-CH', 'fr-CH', 'it-CH'] }, // Switzerland example
    GB: { default: 'en-GB', available: ['en-GB'] },
    IE: { default: 'en-IE', available: ['en-IE'] }
  }
}
export { state }
