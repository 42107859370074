export const tenantConfig = {
  tenants: {
    DK: {
      liteVersion: true,
      modules: ['module1', 'module2']
    },
    FI: {
      liteVersion: true,
      modules: ['module1', 'module2']
    },
    SE: {
      liteVersion: true,
      modules: ['module1', 'module2']
    },
    AT: {
      liteVersion: true,
      modules: ['module1', 'module2']
    },
    FR: {
      liteVersion: true,
      modules: ['module1', 'module2']
    },
    ES: {
      liteVersion: true,
      modules: ['module4']
    },
    PT: {
      liteVersion: true,
      modules: ['module4']
    },
    BE: {
      liteVersion: true,
      modules: ['module4']
    },
    DE: {
      liteVersion: false,
      modules: ['module1', 'module3']
    },
    IE: {
      liteVersion: true,
      modules: ['module1', 'module3']
    },
    GB: {
      liteVersion: true,
      modules: ['module1', 'module3']
    },
    IT: {
      liteVersion: true,
      modules: ['module1', 'module3']
    },
    RO: {
      liteVersion: true,
      modules: ['module1', 'module3']
    },
    EE: {
      liteVersion: true,
      modules: ['module1', 'module3']
    },
    LT: {
      liteVersion: true,
      modules: ['module1', 'module3']
    },
    LV: {
      liteVersion: true,
      modules: ['module1', 'module3']
    },
    HU: {
      liteVersion: true,
      modules: ['module1', 'module3']
    },
    CZ: {
      liteVersion: true,
      modules: ['module1', 'module3']
    },
    PL: {
      liteVersion: true,
      modules: ['module1', 'module3']
    },
    SK: {
      liteVersion: true,
      modules: ['module1', 'module3']
    }
  }
}
